import { useBootstrapData } from '@/store/store';
import { DateFormatter } from '@internationalized/date';
import { DateFormatterOptions } from 'react-aria';
import { useBootstrapLocale } from '../bootstrap/bootstrap-hooks';
export interface FormatterOption<T extends string> {
  name: T;
  formatOption?: DateFormatterOptions;
}

type DateTimeFormatter<T extends string> = Record<
  `${T}Formatter`,
  DateFormatter
>;

export function useDateTimeFormatter<T extends string>(
  formatterOptions: FormatterOption<T>[],
): DateTimeFormatter<T> {
  const locale = useBootstrapLocale()?.code || 'en-GB';
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  const formatters = {} as DateTimeFormatter<T>;
  const bootstrapData = useBootstrapData();
  const isStandardTime = bootstrapData?.timeFormat === '12_hour_clock';
  const hourCycle: DateFormatterOptions['hourCycle'] = isStandardTime
    ? 'h12'
    : 'h23';

  for (const { name, formatOption } of formatterOptions) {
    const updatedFormatOption = hourCycle
      ? { ...formatOption, hourCycle }
      : formatOption;
    formatters[`${name}Formatter`] = new DateFormatter(
      locale,
      updatedFormatOption,
    );
  }

  return formatters;
}
