'use client';

import {
  Dialog,
  DialogTrigger,
  Modal,
  ModalOverlay,
  Button as RAButton,
} from 'react-aria-components';

import Drawer, {
  DrawerBody,
  DrawerHeader,
  drawerModalClass,
} from '@/design-system-components/drawer/drawer';

import { ReactNode } from 'react';

import { Icon } from '@/components/icon';
import { useTranslation } from '@/translation/translation';
import { cn } from '@/utils/tailwind';

import { useIsMobile } from '@/utils/use-is-mobile';

export const CampaignTermsAndConditionsModal = ({
  children,
  terms,
}: {
  children: ReactNode;
  terms: string;
}) => {
  const { t } = useTranslation();
  const isMobile = useIsMobile();

  return (
    <div>
      <DialogTrigger>
        {children}
        {isMobile ? (
          <Drawer>
            {({ close }) => {
              return (
                <>
                  <DrawerHeader title={t('Terms and conditions')} />
                  <DrawerBody>
                    <div className="flex max-h-[60vh] min-h-[50vh] flex-col gap-8">
                      <p>{terms}</p>
                    </div>
                  </DrawerBody>
                </>
              );
            }}
          </Drawer>
        ) : (
          <ModalOverlay
            isDismissable
            className={cn(
              'data-[entering]:animate-in data-[entering]:fade-in',
              'data-[exiting]:animate-out data-[exiting]:fade-out',
              drawerModalClass.animation,
              'group fixed inset-0 grid bg-neutral-900 bg-opacity-70',
              'z-50 items-end overflow-scroll',
            )}
          >
            <Modal
              className={cn(
                'data-[entering]:animate-in data-[exiting]:animate-out',
                'data-[entering]:fade-in data-[exiting]:fade-out',
                drawerModalClass.animation,
                'max-h-[70vh] max-w-xl overflow-y-scroll rounded bg-white lg:self-center lg:justify-self-center',
              )}
            >
              <Dialog
                className="flex flex-col items-center p-8"
                data-testid="campaigns-terms-modal"
              >
                {({ close }) => (
                  <div className="flex flex-col gap-8">
                    <div className="flex justify-between">
                      <span
                        className="mt-2 font-bold text-heading-2"
                        data-testid="campaigns-terms-modal-title"
                      >
                        {t('Terms and conditions')}
                      </span>
                      <RAButton
                        onPress={close}
                        data-testid="campaigns-terms-modal-close-button"
                      >
                        <Icon name="xmark" className="-mt-8 h-5 w-5" />
                      </RAButton>
                    </div>
                    <div
                      className="flex flex-col gap-8"
                      data-testid="campaigns-terms-modal-details"
                    >
                      <p>{terms}</p>
                    </div>
                  </div>
                )}
              </Dialog>
            </Modal>
          </ModalOverlay>
        )}
      </DialogTrigger>
    </div>
  );
};
