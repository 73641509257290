'use client';

import { Container } from '@/components/container';
import { Icon } from '@/components/icon';
import { RcLink } from '@/components/rc-link';
import { CampaignBannerConfig } from '@/config-schema/home-page';
import {
  Carousel,
  CarouselContent,
  CarouselFadedEdges,
  CarouselItem,
} from '@/design-system-components/shared-carousel/carousel';
import {
  CarouselNext,
  CarouselPrevious,
} from '@/design-system-components/shared-carousel/carousel-arrow';
import { CarouselDotButtons } from '@/design-system-components/shared-carousel/carousel-button';
import { useCampaigns } from '@/hooks/campaigns/use-campaigns';
import { useFlagsReady } from '@/hooks/flags-ready/use-flags-ready';
import { CampaignSchema } from '@/schema/campaigns/campaign.schema';
import { useTranslation } from '@/translation/translation';
import { cn } from '@/utils/tailwind';
import { ErrorBoundary } from 'react-error-boundary';
import { CampaignCard } from './campaign-card';

export function CampaignBanner(props: {
  config: CampaignBannerConfig['config'];
}) {
  const campaignsReleased = useFlagsReady('rc_campaigns');

  if (!campaignsReleased) {
    return null;
  }

  return (
    <ErrorBoundary fallback={CampaignBannerFallback(props)}>
      <CampaignBannerContent config={props.config} />
    </ErrorBoundary>
  );
}

export function CampaignBannerFallback(props: {
  config: CampaignBannerConfig['config'];
}) {
  const { t } = useTranslation();

  return (
    <Container>
      <h2 className="mb-6 text-heading-2">{t(props.config.title)}</h2>
      <button
        type="button"
        className={`w-full rounded border border-solid border-neutral-400 bg-white`}
      >
        <div className="flex h-[179px] w-full flex-col items-center justify-center overflow-auto rounded-se rounded-ss object-cover p-2">
          <Icon name="circle-exclamation" className="mb-2 h-10 w-10" />
          <span className="truncate text-left font-bold">Error</span>
          <div className="text-sm">
            You can refresh this page again or visit our{' '}
            <RcLink href={'/help-center'}>Help Centre</RcLink> for more support.
          </div>
        </div>
      </button>
    </Container>
  );
}

function CampaignBannerContent(props: {
  config: CampaignBannerConfig['config'];
}) {
  const { data: campaignData } = useCampaigns({});
  const { t } = useTranslation();

  const campaigns = campaignData?.data || [];

  if (campaigns.length === 0) {
    return null;
  }

  const coverImageHeight = (campaign: CampaignSchema) => {
    if (!campaign.ctaButton && !campaign.termsAndConditions) {
      return 148;
    } else if (!campaign.ctaButton && campaign.termsAndConditions) {
      return 190;
    } else {
      return 248;
    }
  };

  const imageHeight = Math.max(
    ...campaigns.map((campaign) => coverImageHeight(campaign)),
  );

  if (campaigns.length === 1) {
    return (
      <Container>
        <h2 className="mb-6 text-heading-2">{t(props.config.title)}</h2>
        <div
          className={cn('', {
            'lg:h-[148px]': imageHeight === 148,
            'lg:h-[190px]': imageHeight === 190,
            'lg:h-[248px]': imageHeight === 248,
          })}
        >
          <CampaignCard campaign={campaigns[0]} />
        </div>
      </Container>
    );
  }

  return (
    <Container>
      <h2 className="mb-6 text-heading-2" data-testid="campaigns-section">
        {t(props.config.title)}
      </h2>
      <Carousel opts={{ loop: false }}>
        <CarouselContent className="gap-4 lg:gap-8">
          {campaigns.map((campaign, index) => {
            return (
              <CarouselItem
                data-testid="campaigns-tile"
                key={index}
                className={cn('shrink-0 basis-[343px] lg:basis-[992px]', {
                  'lg:h-[148px]': imageHeight === 148,
                  'lg:h-[190px]': imageHeight === 190,
                  'lg:h-[248px]': imageHeight === 248,
                })}
              >
                <CampaignCard campaign={campaign} key={index} />
              </CarouselItem>
            );
          })}
        </CarouselContent>
        <CarouselFadedEdges className="hidden lg:block" />
        <CarouselNext hideOnDisable={true} className="hidden lg:block" />
        <CarouselPrevious hideOnDisable={true} className="hidden lg:block" />
        <CarouselDotButtons />
      </Carousel>
    </Container>
  );
}
