export enum OfferChannelFilter {
  All = 'all',
  Offline = 'offline',
  Online = 'online',
}

export type OfferActivationFilter = 'all' | 'required' | 'not-required';

export enum OfferTypeFilter {
  All = 'all',
  CardLinkedOffer = 'card_linked',
  AffiliateOffer = 'affiliate',
}

export enum OfferCategoryFilter {
  All = 'all',
  AutomotiveService = 'automotive_service',
  FoodAndDrink = 'food_and_drink',
  HealthAndBeauty = 'health_and_beauty',
  Lifestyle = 'lifestyle',
  Retail = 'retail',
  Travel = 'travel',
  Technology = 'technology',
  Others = 'others',
}

export function isOfferCategoryFilter(tag: string): tag is OfferCategoryFilter {
  const s: readonly string[] = Object.values(OfferCategoryFilter);
  return s.includes(tag);
}

export enum OfferSortType {
  BrandAscending = 'brand',
  BrandDescending = '-brand',
  Distance = 'distance',
  Recommended = '-recommended_score',
}
