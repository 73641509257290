'use client';
import { Container } from '@/components/container';
import { Icon } from '@/components/icon';
import { Markdown } from '@/components/markdown';
import { RcLink } from '@/components/rc-link';
import {
  MarketingBannerConfig,
  MarketingBannerVariant,
} from '@/config-schema/home-page';
import { Button } from '@/design-system-components/button/button';
import { optimizeImage } from '@/hooks/utils/optimize-image-util';
import { cn } from '@/utils/tailwind';
import { useIsMobile } from '@/utils/use-is-mobile';
import { useEventListener, useFocusWithin } from 'ahooks';
import { cva } from 'class-variance-authority';
import useEmblaCarousel from 'embla-carousel-react';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

const marketingBannerImageVariants = cva('w-full', {
  variants: {
    variant: {
      'marketing-banner-1': cn(
        'absolute left-0 top-0 z-[-1]',
        'h-full',
        'object-cover',
      ),
      'marketing-banner-2': cn(
        'min-w-0',
        'h-[228px] lg:h-[326px]',
        'rounded-lg',
      ),
    },
    imageStyle: {
      fit: '',
      fill: '',
    },
  },
  compoundVariants: [
    {
      variant: 'marketing-banner-2',
      imageStyle: 'fit',
      className: 'object-fill',
    },
    {
      variant: 'marketing-banner-2',
      imageStyle: 'fill',
      className: 'object-cover',
    },
  ],
  defaultVariants: {
    variant: 'marketing-banner-1',
    imageStyle: 'fill',
  },
});

const marketingBannerContentVariants = cva(
  cn('flex flex-col', 'items-center', 'gap-6 lg:gap-16', 'pb-14 pt-8'),
  {
    variants: {
      variant: {
        'marketing-banner-1': cn(
          'text-white',
          // This part will handle overlay part for image
          'before:block',
          'before:absolute before:top-0 before:h-full before:w-full',
          'before:bg-overlay',
        ),
        'marketing-banner-2': 'text-neutral-900',
      },
      textAlign: {
        'text-left': '',
        'text-center': '',
        'text-right': '',
      },
    },
    compoundVariants: [
      {
        variant: 'marketing-banner-1',
        textAlign: 'text-center',
        className: 'text-center',
      },
      {
        variant: 'marketing-banner-2',
        textAlign: 'text-right',
        className: 'lg:flex-row',
      },
      {
        variant: 'marketing-banner-2',
        textAlign: 'text-left',
        className: 'lg:flex-row-reverse',
      },
    ],
    defaultVariants: {
      variant: 'marketing-banner-1',
      textAlign: 'text-left',
    },
  },
);

const textTransformVariant = cva('', {
  variants: {
    textStyle: {
      uppercase: 'uppercase',
      capitalize: 'capitalize',
      lowercase: 'lowercase',
      none: '',
    },
  },
});

export const MarketingBanner = (props: Omit<MarketingBannerConfig, 'type'>) => {
  const [emblaRef, emblaApi] = useEmblaCarousel({ loop: true });
  const [activeSlide, setActiveSlide] = useState(0);
  const [prevBtnEnabled, setPrevBtnEnabled] = useState(false);
  const [nextBtnEnabled, setNextBtnEnabled] = useState(true);
  const isMobile = useIsMobile();

  const containerRef = React.createRef<HTMLDivElement>();
  const isFocused = useFocusWithin(containerRef);

  const scrollPrev = useCallback(() => {
    emblaApi?.scrollPrev();
    if (!emblaApi?.canScrollPrev()) {
      containerRef.current?.focus();
    }
  }, [emblaApi, containerRef]);

  const scrollNext = useCallback(() => {
    emblaApi?.scrollNext();
    if (!emblaApi?.canScrollNext()) {
      containerRef.current?.focus();
    }
  }, [emblaApi, containerRef]);

  const scrollTo = useCallback(
    (slideIndex: number) => {
      emblaApi?.scrollTo(slideIndex);
    },
    [emblaApi],
  );

  const keyboardNav = useCallback(
    (e: KeyboardEvent) => {
      if (isFocused) {
        switch (e.key) {
          case 'ArrowLeft': {
            scrollPrev();
            break;
          }
          case 'ArrowRight': {
            scrollNext();
            break;
          }
          case 'Space':
          case 'Escape': {
            containerRef.current?.focus();
            break;
          }
        }
      }
    },
    [isFocused, containerRef, scrollPrev, scrollNext],
  );

  useEventListener('keyup', keyboardNav, { target: containerRef });

  const { data } = props;

  const onSelect = useCallback(() => {
    if (!emblaApi) return;
    setActiveSlide(emblaApi.selectedScrollSnap());
    setPrevBtnEnabled(emblaApi.canScrollPrev());
    setNextBtnEnabled(emblaApi.canScrollNext());
  }, [emblaApi, setActiveSlide]);

  useEffect(() => {
    if (!emblaApi) return;
    onSelect();
    emblaApi.on('select', onSelect);
    emblaApi.on('reInit', onSelect);
  }, [emblaApi, onSelect]);

  const activeSlideConfig = data[activeSlide].config;

  return (
    <section ref={containerRef} className="relative">
      <div
        ref={emblaRef}
        className={cn(
          'relative',
          'flex',
          'h-[480px] lg:h-[424px]',
          // Need overflow-hidden for embla to work
          'overflow-hidden',
        )}
      >
        <ul
          className={cn(
            'relative',
            'w-full',
            'flex-1',
            'grid auto-cols-[100%] grid-flow-col auto-rows-[100%]',
          )}
        >
          {data.map(({ body, title, image, label, cta, config }, index) => {
            return (
              <li
                tabIndex={-1}
                aria-hidden={index !== activeSlide}
                key={`carouselBody-${index}`}
                className={cn(
                  'relative',
                  'flex',
                  'lg:px-20',
                  'bg-neutral-100',
                  {
                    isFocused: index === activeSlide,
                  },
                )}
              >
                <Container
                  className={cn(
                    marketingBannerContentVariants({
                      variant: config?.variant,
                      textAlign: config?.textAlign,
                    }),
                    'z-0',
                  )}
                >
                  <img
                    className={cn(
                      marketingBannerImageVariants({
                        variant: config?.variant,
                        imageStyle: config?.imageStyle,
                      }),
                      'flex-shrink-0 lg:flex-1',
                    )}
                    src={optimizeImage(image.url, 716)}
                    alt={image.alt}
                  />
                  <div
                    className={cn(
                      'flex flex-1 flex-col items-center',
                      'z-[999] h-full',
                      'max-w-[1008px]',
                    )}
                    aria-label={'carousel'}
                  >
                    <div
                      className={cn('flex h-full flex-grow flex-row', 'z-[9]')}
                    >
                      <div className="flex flex-col justify-center gap-6">
                        <h1
                          className={cn(
                            'text-banner-title',
                            title.textTransform &&
                              textTransformVariant({
                                textStyle: title.textTransform,
                              }),
                          )}
                        >
                          {title.content}
                        </h1>
                        <Markdown
                          className={
                            body.textTransform &&
                            textTransformVariant({
                              textStyle: body.textTransform,
                            })
                          }
                        >
                          {body.content}
                        </Markdown>

                        {cta && (
                          <div>
                            <RcLink
                              tabIndex={index === activeSlide ? 0 : -1}
                              href={cta.href}
                              target={
                                cta.href?.startsWith('http') ? '_blank' : ''
                              }
                            >
                              <Button excludeFromTabOrder variant="primary">
                                {cta.content}
                              </Button>
                            </RcLink>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="sr-only" aria-live={'polite'} aria-atomic>
                    {label
                      ? `Slide ${label}`
                      : `Slide ${activeSlide + 1} of ${data.length}`}
                  </div>
                </Container>
              </li>
            );
          })}
        </ul>
      </div>

      {!isMobile && data.length > 1 && (
        <>
          <div className="absolute left-0 top-1/2 z-[9] -translate-y-1/2">
            <ArrowBtn
              type={'prev'}
              variant={activeSlideConfig.variant}
              onClick={scrollPrev}
            ></ArrowBtn>
          </div>

          <div className="absolute right-0 top-1/2 z-[9] -translate-y-1/2">
            <ArrowBtn
              type={'next'}
              variant={activeSlideConfig.variant}
              onClick={scrollNext}
            ></ArrowBtn>
          </div>
        </>
      )}

      {data.length > 1 && (
        <MobileNav
          activeSlide={activeSlide}
          totalSlides={data.length}
          scrollPrev={scrollPrev}
          scrollNext={scrollNext}
          variant={activeSlideConfig.variant}
          onPaginationItemClick={scrollTo}
        ></MobileNav>
      )}
    </section>
  );
};

type ArrowBtnProps = {
  type: 'prev' | 'next';
  variant: MarketingBannerVariant;
  onClick: () => void;
  className?: string;
};

function ArrowBtn({ onClick, type, className, variant }: ArrowBtnProps) {
  const iconType = type === 'prev' ? 'chevron-left' : 'chevron-right';

  return (
    <button
      onClick={onClick}
      className={cn(
        type === 'prev' ? 'rounded-r' : 'rounded-l',
        variant === 'marketing-banner-1'
          ? 'bg-[#00000066]'
          : 'bg-neutral-400/30',
        'w-14',
        'h-[96px]',
        'disabled:cursor-not-allowed',
        'focus-link',
        'transition-colors',
        'duration-300',
        {
          'backdrop-blur-md hover:bg-neutral-600/30 active:bg-neutral-800/30':
            variant === 'marketing-banner-2',
        },
        className,
      )}
    >
      <Icon
        name={iconType}
        className={cn(
          'h-6 w-6',
          'm-auto',
          variant === 'marketing-banner-1'
            ? 'fill-neutral-100'
            : 'fill-neutral-900',
        )}
      />
    </button>
  );
}

type PaginationProps = {
  activeSlide: number;
  totalSlides: number;
  classNames?: string;
  variant: MarketingBannerVariant;
  onPaginationItemClick: (index: number) => void;
};

function Pagination({
  activeSlide,
  totalSlides,
  classNames,
  variant,
  onPaginationItemClick,
}: PaginationProps) {
  const dots = [];

  const paginationDotBackground = useMemo(
    () =>
      variant === 'marketing-banner-1'
        ? {
            normal: 'bg-neutral-600',
            active: 'bg-neutral-100',
          }
        : {
            normal: 'bg-primary-300',
            active: 'bg-primary',
          },
    [variant],
  );

  for (let i = 0; i < totalSlides; i++) {
    dots.push(
      <button
        key={`pagination-${i}`}
        className={cn(
          'mx-1',
          'w-2',
          'h-2',
          'rounded-full',
          'transition-all',
          'duration-300',
          'ease-in',
          paginationDotBackground['normal'],
          {
            [`w-4 rounded ${paginationDotBackground['active']}`]:
              activeSlide === i,
            'ml-0': i === 0,
            'mr-0': i === totalSlides - 1,
          },
        )}
        onClick={() => {
          onPaginationItemClick(i);
        }}
      ></button>,
    );
  }

  return <div className={cn('flex', 'justify-center', classNames)}>{dots}</div>;
}

type MobileNavProps = PaginationProps & {
  scrollPrev: () => void;
  scrollNext: () => void;
};

function MobileNav({
  activeSlide,
  totalSlides,
  onPaginationItemClick,
  variant,
}: MobileNavProps) {
  return (
    <div className="absolute bottom-6 flex w-full items-center justify-between">
      <Pagination
        variant={variant}
        activeSlide={activeSlide}
        totalSlides={totalSlides}
        classNames="mx-auto"
        onPaginationItemClick={onPaginationItemClick}
      ></Pagination>
    </div>
  );
}
