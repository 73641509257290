import { OfferChannelFilter } from '@/app/[locale]/products/offers/type';
import { paginationMetaSchema } from '@/schema/shared-schema';
import { z } from 'zod';

export enum OfferStatus {
  Open = 'open', // Active to Members
  Restricted = 'restricted', // Invisible
  Public = 'public', // Active to Public
  Unavailable = 'unavailable', // Unavailable
  Blocked = 'blocked', // Disabled
}

export enum OfferType {
  CardLinked = 'card_linked',
  ShowAndSave = 'show_and_save',
  Affiliate = 'affiliate',
}

export const offerLocationSchema = z.object({
  name: z.string(),
  address: z.string(),
  city: z.string(),
  country: z.string(),
  email: z.string(),
  phone: z.string(),
  lonlat: z.array(z.number(), z.number()),
  active: z.boolean(),
  countrySubdivisionCode: z.string().nullable(),
  locationName: z.string().nullable(),
  postalCode: z.string().nullable(),
});

export const howToUseOptionSchema = z.object({
  header: z.string(),
  body: z.string(),
});

export const descriptionOptionSchema = z.object({
  header: z.string(),
  body: z.string(),
});

export const offerTermsSchema = z.object({
  header: z.string(),
  body: z.string(),
});

const validDayPartSchema = z.optional(z.object({ times: z.array(z.string()) }));

const validDayPartMapSchema = z.object({
  monday: z.optional(validDayPartSchema),
  tuesday: z.optional(validDayPartSchema),
  wednesday: z.optional(validDayPartSchema),
  thursday: z.optional(validDayPartSchema),
  friday: z.optional(validDayPartSchema),
  saturday: z.optional(validDayPartSchema),
  sunday: z.optional(validDayPartSchema),
});

const maxRedemptionSchema = z.object({
  interval: z.enum(['Y', 'M', 'W', 'D']).optional(),
  intervalNumber: z.number().optional(),
  usesPerInterval: z.number().optional(),
});

export const offerSchema = z.object({
  id: z.string(),
  type: z.nativeEnum(OfferType),
  brand: z.string(),
  headline: z.string(),
  title: z.string(),
  categories: z.array(z.string()),
  logoUrl: z.string(),
  coverImageUrl: z.string(),
  mainCategoryIconUrl: z.string().nullable(),
  description: z.string().nullable(),
  shortDescription: z.string().nullable(),
  longDescription: z.union([z.string(), z.array(descriptionOptionSchema)]),
  terms: z.array(offerTermsSchema),
  howToUse: z.union([z.string(), z.array(howToUseOptionSchema)]),
  decoratedHowToUse: z.string(),
  channels: z.array(z.nativeEnum(OfferChannelFilter)),
  email: z.string().nullable(),
  phone: z.string().nullable(),
  promocode: z.string().nullable(),
  trackingUrlAvailable: z.boolean(),
  website: z.string().nullable(),
  startTime: z.string().nullable(),
  endTime: z.string().nullable(),
  status: z.nativeEnum(OfferStatus),
  distanceInMeters: z.number(),
  active: z.boolean(),
  locations: z.array(offerLocationSchema),
  validDayParts: z.array(validDayPartMapSchema),
  excludedDates: z.array(z.string()).nullable(),
  maxRedemptions: maxRedemptionSchema.nullable(),
  maximumRewardCumulative: z.number().nullable(),
  maximumRewardPerTransaction: z.number().nullable(),
  minimumSpend: z.number().nullable(),
  activationRequired: z.boolean(),
  isActivated: z.boolean(),
});

export const offerModelSchema = z.object({
  id: z.string(),
  type: z.literal('offer_detailed'),
  attributes: offerSchema.omit({ id: true }),
});

export const offerArraySchema = z.array(offerSchema);
export type Offer = z.infer<typeof offerSchema>;

export const offersResponseSchema = z.object({
  data: offerArraySchema,
  meta: paginationMetaSchema,
});

export type OffersResponse = z.infer<typeof offersResponseSchema>;

export const offerResponseSchema = offerSchema;

export const offerBrandsSchema = z.array(z.string());

export type OfferBrandsResponse = z.infer<typeof offerBrandsSchema>;

export type OfferLocation = z.infer<typeof offerLocationSchema>;
