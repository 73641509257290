import(/* webpackMode: "eager", webpackExports: ["HomePageRedirectLogic"] */ "/app/apps/web/src/app/home-page-redirect.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SpinnerIcon"] */ "/app/apps/web/src/components/icons/spinner-icon/spinner-icon.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CampaignBannerV2"] */ "/app/apps/web/src/features/campaigns/campaign-banner-v2.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CampaignBanner"] */ "/app/apps/web/src/features/campaigns/campaign-banner.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CarouselV2"] */ "/app/apps/web/src/features/home-page/components/carousel/carousel-v2.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Carousel"] */ "/app/apps/web/src/features/home-page/components/carousel/carousel.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/apps/web/src/features/home-page/components/copy/copy.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["FeaturedProducts"] */ "/app/apps/web/src/features/home-page/components/featured-products/featured-products.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["HeroBannerAndQuickLinksV2"] */ "/app/apps/web/src/features/home-page/components/hero-banner-and-quick-links-v2/hero-banner-and-quick-links-v2.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Hero"] */ "/app/apps/web/src/features/home-page/components/hero/homepage-hero.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["MarketingBanner"] */ "/app/apps/web/src/features/home-page/components/marketing-banner/marketing-banner.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/apps/web/src/features/home-page/components/quick-link/quick-link.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/apps/web/src/features/home-page/components/quick-links/quick-links.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["PointsBalanceWidget"] */ "/app/apps/web/src/features/home-page/components/widgets/points-balance-widget.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["RecommendedForUserBanner"] */ "/app/apps/web/src/features/recommendation/components/recommended-for-user-banner.tsx");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/@unleash+nextjs@1.4.3_next@14.2.3_@babel+core@7.22.1_@opentelemetry+api@1.8.0_react-dom@18.2._ydwizlcryxhljywvpkfmtmg57m/node_modules/@unleash/nextjs/dist/FlagProvider.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/@unleash+nextjs@1.4.3_next@14.2.3_@babel+core@7.22.1_@opentelemetry+api@1.8.0_react-dom@18.2._ydwizlcryxhljywvpkfmtmg57m/node_modules/@unleash/nextjs/dist/hooks.js");
