'use client';
import { OfferTypeFilter } from '@/app/[locale]/products/offers/type';
import { RcLink } from '@/components/rc-link';
import { ProductMetaData } from '@/config-schema/home-page';
import { getDefaultOffersURLSearchParams } from '@/features/offer/offer-filters';
import { useFeatureConfig } from '@/hooks/config/use-feature-config';
import { useFlagsReady } from '@/hooks/flags-ready/use-flags-ready';
import { useUberCredit } from '@/hooks/loyalty-programs/use-uber-credit';
import { useTranslation } from '@/translation/translation';
import { cn } from '@/utils/tailwind';

interface QuickLinkProps {
  id: string;
  productType: ProductMetaData['productType'];
  type: ProductMetaData['type'];
  tileBackgroundColor: string | null;
  image: ProductMetaData['image'] | null;
  openInNewTab?: boolean;
  url?: string;
  title: string;
}

export default function QuickLink(props: QuickLinkProps) {
  let ariaLabel = '';
  let productName = '';

  const { t } = useTranslation();
  const offerSeparation = useFlagsReady('offer-separation');
  const uberEnabled = useFeatureConfig('uberCredit')?.enabled;
  const { uberLoyaltyProgramId } = useUberCredit({ enabled: !!uberEnabled });

  let link = '';
  switch (props.productType) {
    case 'gift-card': {
      link =
        props.type === 'listing'
          ? '/products/gift-cards'
          : `/products/gift-cards/${props.id}`;
      ariaLabel = props.type === 'listing' ? 'Redeem gift cards' : props.title;
      productName = props.title ?? t('Gift cards');
      break;
    }
    case 'points-transfer': {
      link =
        props.type === 'listing'
          ? '/products/frequent-traveler-programs'
          : `/products/frequent-traveler-programs/${props.id}`;
      ariaLabel =
        props.type === 'listing' ? 'Redeem points transfer' : props.title;
      productName = props.title ?? t('Points transfer');
      break;
    }
    case 'carbon': {
      link =
        props.type === 'listing'
          ? '/products/sustainability'
          : `/products/sustainability/${props.id}`;
      ariaLabel =
        props.type === 'listing' ? 'Redeem carbon offset' : props.title;
      productName = props.title ?? t('Carbon offset');
      break;
    }
    case 'cashback': {
      link =
        props.type === 'listing'
          ? '/products/cashback'
          : `/products/cashback/${props.id}`;
      link = `/products/cashback/${props.id ?? ''}`;
      ariaLabel = props.type === 'listing' ? 'Redeem cashback' : props.title;
      productName = props.title ?? t('Cashback');
      break;
    }
    case 'travel': {
      link = 'travel/flights';
      ariaLabel = props.title;
      productName = props.title ?? t('Flight');
      break;
    }
    case 'hotel': {
      link = 'travel/hotels';
      ariaLabel = props.title;
      productName = props.title ?? t('Hotels');
      break;
    }
    case 'offer': {
      if (offerSeparation) {
        return null;
      }
      link =
        props.type === 'listing'
          ? `/products/offers?${getDefaultOffersURLSearchParams()}`
          : `/products/offers/${props.id}`;
      ariaLabel = props.type === 'listing' ? 'Redeem offers' : props.title;
      productName = props.title ?? t('Offer');
      break;
    }
    case 'affiliate-offer': {
      if (!offerSeparation) {
        return null;
      }
      link =
        props.type === 'listing'
          ? `/products/affiliate-offers?${getDefaultOffersURLSearchParams(
              OfferTypeFilter.AffiliateOffer,
            )}`
          : `/products/affiliate-offers/${props.id}`;
      ariaLabel =
        props.type === 'listing' ? 'Redeem affiliate offers' : props.title;
      productName = props.title ?? t('Affiliate Offer');
      break;
    }
    case 'card-linked-offer': {
      if (!offerSeparation) {
        return null;
      }
      link =
        props.type === 'listing'
          ? `/products/card-linked-offers?${getDefaultOffersURLSearchParams(
              OfferTypeFilter.CardLinkedOffer,
            )}`
          : `/products/card-linked-offers/${props.id}`;
      ariaLabel =
        props.type === 'listing' ? 'Redeem card linked offers' : props.title;
      productName = props.title ?? t('Card Linked Offer');
      break;
    }
    case 'crypto': {
      link =
        props.type === 'listing'
          ? '/products/crypto'
          : `/products/crypto/${props.id}`;
      ariaLabel = props.type === 'listing' ? 'Redeem crypto' : props.title;
      productName = props.title ?? t('Crypto');
      break;
    }
    case 'uber-credit': {
      link = `/products/uber-credit/${uberLoyaltyProgramId}`;
      ariaLabel = 'Redeem Uber credit';
      productName = props.title ?? t('Uber credit');
      break;
    }
    case 'url': {
      link = props.url || '';
      ariaLabel = props.title;
      productName = props.title ?? t('URL');
      break;
    }
  }

  // setting tileBackgroundColor to white until it's configurable in AP
  const tileBackgroundColor = '#FFFFFF';

  return (
    <RcLink
      href={link}
      className={cn('rounded border border-transparent hover:border-black')}
      aria-label={ariaLabel}
      target={props.openInNewTab ? '_blank' : '_self'}
      rel={props.openInNewTab ? 'noopener noreferrer' : undefined}
    >
      <div
        style={{
          backgroundColor: tileBackgroundColor,
        }}
        className={cn('flex h-full w-full overflow-hidden rounded lg:flex-col')}
      >
        <div className="w-[144px] lg:w-full lg:flex-1">
          {props.image ? (
            <img
              className="max-h-[280px] w-full max-w-[280px] object-contain"
              src={props.image.url}
              alt={props.image.alt ?? ''}
              loading="lazy"
            />
          ) : (
            // Workaround to not have errors for missing src
            // Can remove later if need
            <div className="m-auto my-3 h-[120px] w-[calc(100%_-_2rem)] bg-primary-200 lg:my-7 lg:h-[160px]" />
          )}
        </div>
        <div className="flex min-h-[56px] w-full items-center p-4 text-xs lg:mt-auto lg:flex-shrink-0 lg:justify-center lg:p-2 lg:text-center lg:text-lg">
          {/* Use body-color here as per https://kaligo.atlassian.net/browse/BUG-2892 */}
          <p className="text-body-color text-heading-4">{productName}</p>
        </div>
      </div>
    </RcLink>
  );
}
