'use client';

import { useHermes } from '@/auth/use-hermes';
import { Container } from '@/components/container';
import { Icon } from '@/components/icon';
import { RcLink, RcLinkVariant } from '@/components/rc-link';
import { QuickLinksV2Config } from '@/config-schema/home-page';
import { RewardsCentralConfiguration } from '@/config-schema/rewards-central-config';
import { usePointsAccounts } from '@/hooks/points-accounts/use-points-accounts';
import { usePointsFormatter } from '@/hooks/utils/use-points-formatter';
import { useTranslation } from '@/translation/translation';
import { cn } from '@/utils/tailwind';
import { MobileQuickLink } from './mobile-quick-link';
import { QuickLinkV2 } from './quick-links-v2';

interface QuickLinksProps {
  config: QuickLinksV2Config['config'];
  features: RewardsCentralConfiguration['application']['features'];
}

export function HeroBannerAndQuickLinksV2({
  config,
  ...props
}: QuickLinksProps) {
  const { user } = useHermes();
  const pointsFormatter = usePointsFormatter();
  const { data: pointsAccount } = usePointsAccounts();
  const { t } = useTranslation();

  return (
    <>
      <div
        style={{
          backgroundColor: config.backgroundColor,
          color: config.textColor,
        }}
      >
        <Container>
          <p className="mb-0 pt-4 text-heading-2 lg:pt-10">
            {`${t('Welcome back')}, ${user?.firstName}!`}
          </p>
        </Container>

        <Container>
          <div
            className={cn(
              'w-full translate-y-1/2 px-4',
              'flex items-center justify-start',
              'rounded-custom bg-white px-8 py-4 text-primary shadow-elevation-medium',
            )}
          >
            <div className="flex w-full items-center justify-between lg:w-auto lg:shrink-0">
              <div className="flex flex-col lg:flex-row">
                <div className="pr-4">{t('You have')}</div>

                <div
                  data-testid="header-points-balance"
                  className="ml-auto flex items-center gap-2 pr-6 lg:ml-0"
                >
                  <Icon name="gem" />
                  <span>{pointsFormatter(pointsAccount?.pointsBalance)}</span>
                </div>
              </div>

              <RcLink
                href="/rewards-summary/points-activity"
                variant={RcLinkVariant.SECONDARY}
                className="text-secondary"
              >
                <p className="hidden lg:block">{t('Points activity')}</p>
                <Icon name="chevron-right" className="lg:hidden" />
              </RcLink>
            </div>

            <div
              className="mx-8 hidden h-[44px] w-[1px] border-l lg:block"
              style={{ borderColor: config.textColor, opacity: 0.25 }}
            />

            <QuickLinkV2 {...props} className={'hidden lg:flex'} />
          </div>
        </Container>
      </div>

      <MobileQuickLink {...props} className="pt-4 lg:hidden" />
    </>
  );
}
