'use client';
import { Container } from '@/components/container';
import { QuickLinksConfig } from '@/config-schema/home-page';
import { useTranslation } from '@/translation/translation';
import { cn } from '@/utils/tailwind';
import { Children, ReactNode } from 'react';

interface QuickLinksProps {
  config: QuickLinksConfig['config'];
  children: ReactNode;
  count: number;
}

const quantity = 8;
export default function QuickLinks({
  config,
  children,
  count,
}: QuickLinksProps) {
  const { t } = useTranslation();

  // setting backgroundColor to none until it's configurable in AP
  const backgroundColor = 'none';

  return (
    <Container style={{ backgroundColor }}>
      <h2 className="text-heading-2">
        {config.title || t('Start redeeming now')}
      </h2>
      <div
        className={cn('mt-6 lg:mt-8', 'grid grid-cols-1 gap-4', 'lg:gap-8', {
          'lg:grid-cols-[repeat(auto-fit,_minmax(264px,_1fr))]': count > 6,
          'lg:grid-cols-[repeat(auto-fit,_minmax(176px,_max-content))]':
            count <= 6,
          'lg:grid-cols-[repeat(auto-fit,_minmax(176px,_1fr))]': count === 4,
        })}
        data-testid={'quick-links'}
      >
        {Children.map(children, (child, index) => {
          if (index < quantity) {
            return child;
          }
        })}
      </div>
    </Container>
  );
}
