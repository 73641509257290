import { z } from 'zod';

export const campaignSchema = z.object({
  id: z.string(),
  status: z.string(),
  title: z.string(),
  description: z.string(),
  coverImageUrl: z.string(),
  termsAndConditions: z.string().nullable(),
  qualifyingEndTime: z.string().nullable(),
  ctaButton: z.boolean(),
  ctaText: z.string().nullable(),
  ctaUrl: z.string().nullable(),
  ranking: z.number(),
  userId: z.string(),
});

export type CampaignSchema = z.infer<typeof campaignSchema>;

export const campaignsResponseSchema = z.object({
  data: z.array(campaignSchema),
  meta: z.object({
    count: z.number(),
  }),
});

export type CampaignsResponseSchema = z.infer<typeof campaignsResponseSchema>;
