'use client';
import { Container } from '@/components/container';
import { CarouselConfig } from '@/config-schema/home-page';
import { useTranslation } from '@/translation/translation';
import { cn } from '@/utils/tailwind';
import { useEventListener, useFocusWithin } from 'ahooks';
import { cva } from 'class-variance-authority';
import useEmblaCarousel from 'embla-carousel-react';
import React, { useCallback, useEffect, useState } from 'react';

export const Carousel = (props: Omit<CarouselConfig, 'type'>) => {
  const [emblaRef, emblaApi] = useEmblaCarousel({ loop: true });
  const [activeSlide, setActiveSlide] = useState(0);
  const [prevBtnEnabled, setPrevBtnEnabled] = useState(false);
  const [nextBtnEnabled, setNextBtnEnabled] = useState(true);

  const containerRef = React.createRef<HTMLDivElement>();
  const isFocused = useFocusWithin(containerRef);

  const { t } = useTranslation();

  const scrollPrev = useCallback(() => {
    emblaApi?.scrollPrev();
    if (!emblaApi?.canScrollPrev()) {
      containerRef.current?.focus();
    }
  }, [emblaApi, containerRef]);

  const scrollNext = useCallback(() => {
    emblaApi?.scrollNext();
    if (!emblaApi?.canScrollNext()) {
      containerRef.current?.focus();
    }
  }, [emblaApi, containerRef]);

  const scrollTo = useCallback(
    (slideIndex: number) => {
      emblaApi?.scrollTo(slideIndex);
    },
    [emblaApi],
  );

  const keyboardNav = useCallback(
    (e: KeyboardEvent) => {
      if (isFocused) {
        switch (e.key) {
          case 'ArrowLeft': {
            scrollPrev();
            break;
          }
          case 'ArrowRight': {
            scrollNext();
            break;
          }
          case 'Space':
          case 'Escape': {
            containerRef.current?.focus();
            break;
          }
        }
      }
    },
    [isFocused, containerRef, scrollPrev, scrollNext],
  );

  useEventListener('keyup', keyboardNav, { target: containerRef });

  const { data } = props;

  const onSelect = useCallback(() => {
    if (!emblaApi) return;
    setActiveSlide(emblaApi.selectedScrollSnap());
    setPrevBtnEnabled(emblaApi.canScrollPrev());
    setNextBtnEnabled(emblaApi.canScrollNext());
  }, [emblaApi, setActiveSlide]);

  useEffect(() => {
    if (!emblaApi) return;
    onSelect();
    emblaApi.on('select', onSelect);
    emblaApi.on('reInit', onSelect);
  }, [emblaApi, onSelect]);

  const variantClass = cva('', {
    variants: {
      image: {
        'carousel-1': 'order-2 lg:order-1',
        'carousel-2': 'order-2',
      },
      content: {
        'carousel-1': 'order-1 lg:order-2',
        'carousel-2': 'order-1',
      },
      textTransform: {
        uppercase: 'uppercase',
        capitalize: 'capitalize',
        lowercase: 'lowercase',
        none: '',
      },
    },
  });

  return (
    <section className="bg-white">
      <Container
        tabIndex={0}
        ref={containerRef}
        className={cn(
          'flex flex-col items-center justify-between lg:flex-row lg:py-12 ',
        )}
        aria-label={'carousel'}
      >
        <ArrowBtn
          ariaLabel={t('Previous')}
          type={'prev'}
          onClick={scrollPrev}
          isActive={prevBtnEnabled}
          className="hidden lg:inline-block"
        ></ArrowBtn>
        <div ref={emblaRef} className={cn('overflow-hidden lg:mx-8')}>
          <ul className={cn('grid auto-cols-[100%] grid-flow-col lg:mb-12')}>
            {data.map(({ body, title, image, label, variant }, index) => {
              return (
                <li
                  tabIndex={-1}
                  aria-hidden={index !== activeSlide}
                  key={`carouselBody-${index}`}
                  className={cn(
                    'grid grid-cols-1 items-center gap-6',
                    'lg:mx-2 lg:grid-cols-2 lg:gap-8',
                    { isFocused: index === activeSlide },
                  )}
                >
                  <img
                    className={cn(
                      'h-[240px] w-full object-cover',
                      'lg:h-[320px] lg:w-[488px]',
                      variantClass({ image: variant }),
                    )}
                    src={image.url}
                    alt={image.alt}
                  />
                  <div
                    className={cn(
                      'flex flex-col gap-4',
                      variantClass({ content: variant }),
                    )}
                  >
                    <h2
                      className={cn(
                        'text-heading-2',
                        variantClass({ textTransform: title.textTransform }),
                      )}
                    >
                      {title.content}
                    </h2>
                    <p
                      className={variantClass({
                        textTransform: body.textTransform,
                      })}
                    >
                      {body.content}
                    </p>
                  </div>
                  <div
                    className={cn('sr-only')}
                    aria-live={'polite'}
                    aria-atomic
                  >
                    {label
                      ? `Slide ${label}`
                      : `Slide ${activeSlide + 1} of ${data.length}`}
                  </div>
                </li>
              );
            })}
          </ul>

          <Pagination
            activeSlide={activeSlide}
            totalSlides={data.length}
            onPaginationItemClick={scrollTo}
            className="hidden lg:flex"
          ></Pagination>
        </div>
        <ArrowBtn
          ariaLabel={t('Next')}
          type={'next'}
          onClick={scrollNext}
          isActive={nextBtnEnabled}
          className="hidden lg:inline-block"
        ></ArrowBtn>

        <MobileNav
          activeSlide={activeSlide}
          totalSlides={data.length}
          scrollPrev={scrollPrev}
          scrollNext={scrollNext}
          onPaginationItemClick={scrollTo}
          prevBtnEnabled={prevBtnEnabled}
          nextBtnEnabled={nextBtnEnabled}
        ></MobileNav>
      </Container>
    </section>
  );
};

type ArrowBtnProps = {
  type: 'prev' | 'next';
  onClick: () => void;
  isActive: boolean;
  ariaLabel: string;
  className?: string;
};

function ArrowBtn(props: ArrowBtnProps) {
  return (
    <button
      onClick={props.onClick}
      disabled={!props.isActive}
      className={cn(
        'rounded',
        'border border-primary',
        'hover:bg-primary-200',
        'min-w-[56px]',
        'h-[56px]',
        'disabled:cursor-not-allowed',
        'focus-link',
        props.className,
      )}
      aria-label={props.ariaLabel}
    >
      <div
        className={cn(
          'm-auto',
          'p-0',
          'border-2',
          'border-transparent',
          'border-t-primary',
          'w-3',
          'h-3',
          {
            'border-r-primary': props.type === 'next',
            'rotate-45': props.type === 'next',
            '-translate-x-1': props.type === 'next',
            'border-l-primary': props.type === 'prev',
            '-rotate-45': props.type === 'prev',
            'translate-x-1': props.type === 'prev',
          },
        )}
      ></div>
    </button>
  );
}

type PaginationProps = {
  activeSlide: number;
  totalSlides: number;
  className?: string;
  onPaginationItemClick: (index: number) => void;
};

function Pagination({
  activeSlide,
  totalSlides,
  className,
  onPaginationItemClick,
}: PaginationProps) {
  const dots = [];
  const { t } = useTranslation();

  for (let i = 0; i < totalSlides; i++) {
    dots.push(
      <button
        aria-label={t(`Page ${i + 1}`)}
        key={`pagination-${i}`}
        className={cn(
          'mx-1',
          'w-2',
          'h-2',
          'bg-primary-300',
          'rounded-full',
          'transition',
          'duration-300',
          'ease-in',
          {
            'w-4 rounded bg-primary': activeSlide === i,
            'ml-0': i === 0,
            'mr-0': i === totalSlides - 1,
          },
        )}
        onClick={() => {
          onPaginationItemClick(i);
        }}
      ></button>,
    );
  }

  return <div className={cn('flex', 'justify-center', className)}>{dots}</div>;
}

type MobileNavProps = PaginationProps & {
  scrollPrev: () => void;
  scrollNext: () => void;
  prevBtnEnabled: boolean;
  nextBtnEnabled: boolean;
};

function MobileNav({
  activeSlide,
  totalSlides,
  scrollPrev,
  scrollNext,
  onPaginationItemClick,
  prevBtnEnabled,
  nextBtnEnabled,
}: MobileNavProps) {
  const { t } = useTranslation();
  return (
    <div
      className={cn(
        'mt-6 flex w-full items-center justify-between lg:mt-0 lg:hidden',
      )}
    >
      <ArrowBtn
        ariaLabel={t('Previous')}
        type={'prev'}
        onClick={scrollPrev}
        isActive={prevBtnEnabled}
      ></ArrowBtn>
      <Pagination
        activeSlide={activeSlide}
        totalSlides={totalSlides}
        onPaginationItemClick={onPaginationItemClick}
      ></Pagination>
      <ArrowBtn
        ariaLabel={t('Next')}
        type={'next'}
        onClick={scrollNext}
        isActive={nextBtnEnabled}
      ></ArrowBtn>
    </div>
  );
}
