import { RedirectModalContext } from '@/app/redirect-modal-provider';
import { customNavigation } from '@/components/header/utils';
import { Icon } from '@/components/icon';
import { RcLink } from '@/components/rc-link';
import { MenuItemType } from '@/config-schema/navigation';
import { cn } from '@/utils/tailwind';
import { useContext } from 'react';

export function QuicklinklItem({
  menuItem,
  className,
  titleClassName,
}: {
  menuItem: MenuItemType;
  className?: string;
  titleClassName?: string;
}) {
  const { setShowRedirectModal } = useContext(RedirectModalContext);

  return (
    <RcLink
      href={menuItem.href}
      className={cn(
        'flex shrink-0 items-center justify-center gap-2',
        className,
      )}
      onClick={(event: React.MouseEvent<HTMLAnchorElement>) => {
        customNavigation(menuItem, event, setShowRedirectModal);
      }}
    >
      <div className="flex h-10 w-10 items-center justify-center rounded-full bg-[#E9EAEF] p-3">
        {menuItem.iconName && (
          <Icon
            name={menuItem.iconName}
            className="h-5 w-5"
            width={20}
            height={20}
          />
        )}
      </div>
      <p className={titleClassName}>{menuItem.translation}</p>
    </RcLink>
  );
}
