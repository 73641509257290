import { useFlagsReady } from '@/hooks/flags-ready/use-flags-ready';
import { ReactNode } from 'react';
import { ErrorBoundary } from 'react-error-boundary';

export function RecommendedForUserFeatureFlag({
  children,
}: {
  children: ReactNode;
}) {
  const isFeatureFlagEnabled = useFlagsReady('rc_recommended_for_user');
  if (!isFeatureFlagEnabled) return null;

  return <ErrorBoundary fallback={<></>}>{children}</ErrorBoundary>;
}
