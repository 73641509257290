import { RewardsCentralConfiguration } from '@/config-schema/rewards-central-config';
import {
  Carousel,
  CarouselContent,
} from '@/design-system-components/shared-carousel/carousel';
import {
  CarouselNext,
  CarouselPrevious,
} from '@/design-system-components/shared-carousel/carousel-arrow';
import { useHeaderLinks } from '@/hooks/config/use-header-links';
import { cn } from '@/utils/tailwind';
import { QuicklinklItem } from './quicklink-item';

interface QuickLinksProps {
  features: RewardsCentralConfiguration['application']['features'];
  className?: string;
}

export function QuickLinkV2({ features, className }: QuickLinksProps) {
  const { headerLinks } = useHeaderLinks(features);

  return (
    <Carousel
      className={cn(
        'flex items-center justify-center overflow-hidden',
        className,
      )}
      opts={{ slidesToScroll: 3, duration: 30, watchDrag: false }}
    >
      <CarouselContent className="gap-6" viewableClassName="mr-6">
        {headerLinks.map((menuItem) => (
          <QuicklinklItem menuItem={menuItem} key={menuItem.translation} />
        ))}
      </CarouselContent>
      <CarouselPrevious
        variant="variant2"
        className="mr-2"
        iconColorOnDisabled="text-neutral-300"
      />
      <CarouselNext variant="variant2" iconColorOnDisabled="text-neutral-300" />
    </Carousel>
  );
}
