'use client';

import { HomepageProductsCarousel } from '@/components/homepage-products-carousel/homepage-products-carousel';
import { Icon } from '@/components/icon';
import { RcLink } from '@/components/rc-link';
import { CampaignBannerConfig } from '@/config-schema/home-page';
import { Button } from '@/design-system-components/button/button';
import { CarouselItem } from '@/design-system-components/shared-carousel/carousel';
import { useCampaigns } from '@/hooks/campaigns/use-campaigns';
import { useFlagsReady } from '@/hooks/flags-ready/use-flags-ready';
import { useDateTimeFormatter } from '@/hooks/utils/use-date-time-formatter';
import { useTranslation } from '@/translation/translation';
import { CAMPAIGN_CARD_DATE_TIME_FORMAT_OPTION } from '@/utils/date-time-format-options';
import { generateImageUrl } from '@/utils/images-urls';
import { cn } from '@/utils/tailwind';
import { ErrorBoundary } from 'react-error-boundary';
import { CampaignBannerFallback } from './campaign-banner';
import { CampaignTermsAndConditionsModal } from './terms-and-conditions-modal';

export function CampaignBannerV2(props: {
  config: CampaignBannerConfig['config'];
}) {
  const campaignsReleased = useFlagsReady('rc_campaigns');

  if (!campaignsReleased) {
    return null;
  }

  return (
    <ErrorBoundary fallback={CampaignBannerFallback(props)}>
      <CampaignBannerContent config={props.config} />
    </ErrorBoundary>
  );
}

function CampaignBannerContent({
  config: { title },
}: {
  config: CampaignBannerConfig['config'];
}) {
  const { data: campaignData } = useCampaigns({});
  const { t } = useTranslation();

  const campaigns = campaignData?.data || [];

  const { campaignCardDateFormatter } = useDateTimeFormatter([
    CAMPAIGN_CARD_DATE_TIME_FORMAT_OPTION,
  ]);

  if (campaigns.length === 0) {
    return null;
  }

  return (
    <HomepageProductsCarousel
      className="gap-6 container-responsive"
      viewableClassName="mx-[-112px] px-[112px]"
      carouselWrapperClassName="gap-4"
      showPagination
      hideOnNotSlidable
      titleComponent={<p className="text-heading-2">{title}</p>}
      data-testid="campaigns-section"
    >
      {campaigns.map((campaign) => (
        <CarouselItem
          key={campaign.id}
          className={cn(
            'w-[min(calc(100%-1rem),400px)] shrink-0 lg:w-[992px]',
            'flex flex-col-reverse lg:flex-row',
            'rounded-custom bg-secondary-100 ',
          )}
          data-testid="campaigns-tile"
        >
          <div className="flex flex-1 flex-col p-6">
            <p className="font-bold leading-6" data-testid="campaign-title">
              {t(campaign.title)}
            </p>

            <p
              className="mt-2 text-neutral-600"
              data-testid="campaign-description"
            >
              {t(campaign.description)}
            </p>

            {!!campaign.qualifyingEndTime && (
              <div className="mt-4 flex flex-row items-center gap-2 text-neutral-600">
                <Icon name="calendar" className="h-4 w-4" />
                <p data-testid="campaign-end-date">
                  {t('Ends ')}
                  {campaignCardDateFormatter.format(
                    new Date(campaign.qualifyingEndTime),
                  )}
                </p>
              </div>
            )}

            {!!campaign.termsAndConditions && (
              <CampaignTermsAndConditionsModal
                terms={campaign.termsAndConditions}
              >
                <Button
                  variant={'noBackground'}
                  className="m-0 mt-4 line-clamp-1 p-0 font-normal text-secondary"
                  data-testid="campaign-terms-and-conditions-button"
                >
                  {t('Terms and conditions')}
                </Button>
              </CampaignTermsAndConditionsModal>
            )}

            {campaign.ctaButton && campaign.ctaUrl && campaign.ctaText ? (
              <RcLink
                href={campaign.ctaUrl}
                target={
                  campaign.ctaUrl.startsWith('http') ? '_blank' : undefined
                }
                className={cn(
                  'mt-6 w-fit min-w-[182px] px-6 py-2',
                  'rounded border border-primary bg-neutral-100',
                  'text-center text-sm font-bold',
                )}
                data-testid="campaign-cta-button"
              >
                {campaign.ctaText}
              </RcLink>
            ) : null}
          </div>

          <div className="w-full lg:relative lg:w-[456px]">
            <img
              className={
                'h-full w-full rounded-se-custom rounded-ss-custom object-cover lg:absolute lg:rounded-ee-custom lg:rounded-ss-none'
              }
              src={generateImageUrl({
                url: campaign.coverImageUrl,
                width: 456,
              })}
              alt={campaign.title}
            />
          </div>
        </CarouselItem>
      ))}
    </HomepageProductsCarousel>
  );
}
