'use client';

import { Icon } from '@/components/icon';
import { cn } from '@/utils/tailwind';
import { cva } from 'class-variance-authority';
import { HTMLAttributes, forwardRef, useContext } from 'react';
import { CarouselContext } from './carousel';

const CarouselPrevious = forwardRef<
  HTMLButtonElement,
  HTMLAttributes<HTMLButtonElement> & {
    hideOnDisable?: boolean;
    variant?: 'variant1' | 'variant2';
    iconColorOnDisabled?: string;
    iconClassName?: string;
  }
>(
  (
    {
      className,
      hideOnDisable,
      variant = 'variant1',
      iconColorOnDisabled,
      iconClassName,
      ...props
    },
    ref,
  ) => {
    const { btnBgColor, prevBtnDisabled, onPrevButtonClick } =
      useContext(CarouselContext);

    const arrowVariants = cva('', {
      variants: {
        buttonVariant: {
          variant1: cn(
            'absolute h-16 w-16 rounded',
            'top-1/2 -translate-y-1/2',
          ),
          variant2: '',
        },
        backgroundColorVariant: {
          variant1: btnBgColor,
          variant2: '',
        },
        chosenButtonVariant: {
          variant1: 'w-4 h-[6px] rounded bg-primary',
          variant2: 'w-4 rounded bg-primary',
        },
        iconVariant: {
          variant1: 'h-6 w-6 text-white',
          variant2: '',
        },
        iconColorOnDisabled: {
          variant1: '',
          variant2: iconColorOnDisabled,
        },
      },
      defaultVariants: {
        buttonVariant: null,
        backgroundColorVariant: null,
        chosenButtonVariant: null,
        iconVariant: null,
        iconColorOnDisabled: null,
      },
    });

    return (
      <button
        ref={ref}
        className={cn(arrowVariants({ buttonVariant: variant }), className, {
          'hidden md:hidden lg:hidden': hideOnDisable && prevBtnDisabled,
        })}
        style={{
          backgroundColor: arrowVariants({ backgroundColorVariant: variant }),
        }}
        disabled={prevBtnDisabled}
        onClick={onPrevButtonClick}
        {...props}
      >
        <Icon
          name="chevron-left"
          className={cn(
            arrowVariants({ iconVariant: variant }),
            iconClassName,
            {
              [arrowVariants({ iconColorOnDisabled: variant })]:
                prevBtnDisabled,
            },
          )}
        />
      </button>
    );
  },
);
CarouselPrevious.displayName = 'CarouselPrevious';

const CarouselNext = forwardRef<
  HTMLButtonElement,
  HTMLAttributes<HTMLButtonElement> & {
    hideOnDisable?: boolean;
    variant?: 'variant1' | 'variant2';
    iconColorOnDisabled?: string;
    iconClassName?: string;
  }
>(
  (
    {
      className,
      hideOnDisable,
      variant = 'variant1',
      iconColorOnDisabled,
      iconClassName,
      ...props
    },
    ref,
  ) => {
    const { btnBgColor, nextBtnDisabled, onNextButtonClick } =
      useContext(CarouselContext);

    const arrowVariants = cva('', {
      variants: {
        buttonVariant: {
          variant1: cn(
            'absolute h-16 w-16 rounded',
            'right-0 top-1/2 -translate-y-1/2',
          ),
          variant2: '',
        },
        backgroundColorVariant: {
          variant1: btnBgColor,
          variant2: '',
        },
        chosenButtonVariant: {
          variant1: 'w-4 h-[6px] rounded bg-primary',
          variant2: 'w-4 rounded bg-primary',
        },
        iconVariant: {
          variant1: 'h-6 w-6 text-white',
          variant2: '',
        },
        iconColorOnDisabled: {
          variant1: '',
          variant2: iconColorOnDisabled,
        },
      },
      defaultVariants: {
        buttonVariant: null,
        backgroundColorVariant: null,
        chosenButtonVariant: null,
        iconVariant: null,
        iconColorOnDisabled: null,
      },
    });

    return (
      <button
        ref={ref}
        className={cn(arrowVariants({ buttonVariant: variant }), className, {
          'hidden md:hidden lg:hidden': hideOnDisable && nextBtnDisabled,
        })}
        style={{
          backgroundColor: arrowVariants({ backgroundColorVariant: variant }),
        }}
        disabled={nextBtnDisabled}
        onClick={onNextButtonClick}
        {...props}
      >
        <Icon
          name="chevron-right"
          className={cn(
            arrowVariants({ iconVariant: variant }),
            iconClassName,
            {
              [arrowVariants({ iconColorOnDisabled: variant })]:
                nextBtnDisabled,
            },
          )}
        />
      </button>
    );
  },
);
CarouselNext.displayName = 'CarouselNext';

export { CarouselNext, CarouselPrevious };
