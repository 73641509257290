'use client';

import { useMemo } from 'react';

import { useHermes } from '@/auth/use-hermes';
import { Container } from '@/components/container';
import { Icon } from '@/components/icon';
import { RcLink } from '@/components/rc-link';
import { HomepagePointsBalanceWidgetConfig } from '@/config-schema/home-page';
import { usePointsAccounts } from '@/hooks/points-accounts/use-points-accounts';
import { usePointsFormatter } from '@/hooks/utils/use-points-formatter';
import { useTranslation } from '@/translation/translation';

export function PointsBalanceWidget(props: {
  config: HomepagePointsBalanceWidgetConfig['config'];
}) {
  const { data: pointsAccount } = usePointsAccounts();

  const pointsFormatter = usePointsFormatter();

  const { loggedIn } = useHermes();
  const { t } = useTranslation();

  const pointsValue = useMemo(() => {
    return pointsFormatter(pointsAccount?.pointsBalance);
  }, [pointsFormatter, pointsAccount?.pointsBalance]);

  const widgetContainerStyles = {
    backgroundColor: props.config?.backgroundColor,
    color: props.config?.textColor,
  };

  return !!loggedIn && !!pointsValue ? (
    <div
      className="-mt-12 bg-white lg:-mt-[72px]"
      style={widgetContainerStyles}
    >
      <Container className="py-4">
        <div className="items-baseline lg:flex">
          <p className="mb-0 border-neutral-400 text-lg lg:mr-6 lg:border-r lg:pr-6">
            {t('You have:')} <strong>{pointsValue}</strong>
          </p>
          <RcLink href="/rewards-summary/points-activity">
            {t('View My Rewards Summary')}
            <Icon name="chevron-right" className="ml-2" />
          </RcLink>
        </div>
      </Container>
    </div>
  ) : (
    <></>
  );
}
