'use client';
import { CarouselConfig } from '@/config-schema/home-page';
import {
  Carousel,
  CarouselContent,
  CarouselItem,
} from '@/design-system-components/shared-carousel/carousel';
import { CarouselArrowWithPagination } from '@/design-system-components/shared-carousel/carousel-arrow-with-pagination';
import { generateImageUrl } from '@/utils/images-urls';
import { cn } from '@/utils/tailwind';
import { useIsMobile } from '@/utils/use-is-mobile';
import { cva } from 'class-variance-authority';

const variantClass = cva('', {
  variants: {
    textTransform: {
      uppercase: 'uppercase',
      capitalize: 'capitalize',
      lowercase: 'lowercase',
      none: '',
    },
  },
});

export const CarouselV2 = ({
  data,
  className,
}: Omit<CarouselConfig, 'type'> & { className?: string }) => {
  const isMobile = useIsMobile();

  const hideIndicatorButton = data.length <= 1;

  return (
    <div className="bg-neutral-200">
      <Carousel
        className={cn('py-10 lg:container-responsive', className)}
        opts={{ loop: false, watchDrag: isMobile }}
      >
        <CarouselContent viewableClassName="lg:mx-[88px]">
          {data.map(({ body, title, image }, index) => (
            <CarouselItem
              key={index}
              className="flex w-full shrink-0 flex-col lg:flex-row lg:gap-10"
            >
              <img
                className={cn(
                  'h-[200px] object-cover lg:h-[248px] lg:rounded-custom',
                )}
                src={generateImageUrl({
                  url: image.url,
                  height: isMobile ? 200 : 248,
                })}
                alt={image.alt}
                height={isMobile ? 200 : 248}
              />

              <div className="flex h-full flex-col gap-2 p-4 lg:p-0">
                <p
                  className={cn(
                    'text-heading-2',
                    variantClass({ textTransform: title.textTransform }),
                  )}
                >
                  {title.content}
                </p>
                <p
                  className={cn(
                    variantClass({
                      textTransform: body.textTransform,
                    }),
                    'text-neutral-600',
                  )}
                >
                  {body.content}
                </p>
                <CarouselArrowWithPagination
                  currentIndex={index}
                  className={cn('ml-auto mt-auto lg:ml-0', {
                    hidden: hideIndicatorButton,
                  })}
                  showPagination
                />
              </div>
            </CarouselItem>
          ))}
        </CarouselContent>
      </Carousel>
    </div>
  );
};
